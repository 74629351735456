.HeaderText {
    color: var(--2, #0F0F28);
    /* НЦ Заголовок */
    font-family: "Manrope";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}


.buttonCreateLink2 {
    margin: 10px;
    width: 150px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #4A6FF5;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.audioTrackStyle {
    width: 500px;
    overflow: hidden;
}

.audioStyle {
    width: 100%;
}

.buttonStyle {
    cursor: pointer;
    border-radius: 15px;
    background: #4A6FF5;
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.buttonStyle2 {
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
    background: #4A6FF5;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
}

.notText {
    font-size: 30px;
    text-align: center;
}

.textAge {
    font-size: 24px;
}

.shadowBox{
    position: fixed;
    background: rgb(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    cursor: wait;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
}

.sendText{
    font-size: 32px;
    color: white;
    user-select: none;
}

@media(max-width:500px) {
    .audioStyle {
        width: 100vw;
    }

    .buttonStyle {
        width: 100vw;
    }

    .audioTrackStyle {
        width: 200px;
        overflow: hidden;
    }
}