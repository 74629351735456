.notText {
    font-size: 30px;
    text-align: center;
}

.buttonAdd {
    margin-top: 10px;
    justify-content: center;
    display: flex;
}
.shadowBox{
    position: fixed;
    background: rgb(0, 0, 0, 0.4);
    width: 100vw;
    height: 100vh;
    cursor: wait;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 300;
}

.sendText{
    font-size: 32px;
    color: white;
    user-select: none;
}

.buttonCreateLink2 {
    margin: 10px;
    width: 150px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #4A6FF5;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.textAreaStyle{
    width: 300px;
    min-height: 100px;
}
.wrapperAge {
    
}

@media (max-width:280px) {
    .wrapperAge {
        display: flex;
        flex-direction: column;
    }

    .textAge{
        text-align: center;
    }

    .textAreaStyle{
        width: 90vw;
        margin-left: 4vw;
    }

}