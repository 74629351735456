.menuWrapper {
    width:  849px;
    height: 342px;
    background: #F6F6F6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}


@media(max-width:849px) {
    .menuWrapper {
        width: 100%;
    }
}


.headerText {
    font-family: 'Roboto-LightItalic';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #919191;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 342px;
}

.inputWrapper {
    width: 600px;
    height: 40px;
    background: #F6F6F6;
    border: 1px solid #919191;
    border-radius: 16px;
    padding-left: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #919191;
}

.inputWrapper::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #919191;
}

.buttonWrapper {
    width: 218px;
    height: 58px;
    background: #919191;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
    cursor: pointer;
}

.buttonWrapper:hover {
    background: #818181;
}