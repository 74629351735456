.linksWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.linkWrapper {
    word-break:break-all;
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
    cursor: pointer;
    max-width: 550px;
    overflow: hidden;
}

.text {
    margin-bottom: 10px;
    color: var(--2, #0F0F28);
    font-family: "Manrope";
    font-size: 26px;
    font-style: normal;
    font-weight: 800;
    line-height: 120%;
 }

.buttonCreateLink {
    width: 360px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    background: #4A6FF5;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.buttonCreateLink2 {
    margin: 10px;
    width: 150px;
    height: 40px;
    border-radius: 20px;
    background: #4A6FF5;
    color: var(--c, #FAFAFF);
    font-family: "Manrope";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tooltipStyle {
    display: none;
    position: absolute;
    left: 0;
    top: 40px;
    background: white;
    border: 1px solid #0F0F28;
    border-radius: 16px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
}

.linksWrapper2{

}

/* Скрываем scrollbar для Chrome, Safari и Opera */
.linksWrapper2::-webkit-scrollbar {
    display: none;
  }
   
  /* Скрываем scrollbar для IE, Edge и Firefox */
  .linksWrapper2 {
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
  }

.ageWrapper{
    margin-left: 50px;
}

.noteWrapper{
    margin-left: 50px;
}

.wrapperLink{

}

@media(max-width:500px) {
    .linkWrapper {
        width: 200px;
        overflow: hidden;
    }
}

@media(max-width:360px) {
    .buttonCreateLink {
        width: 100vw;
        margin-top: 0;
    }

    .ageWrapper{
        margin-left: 0px;
        text-align: center;
        
    }
    
    .noteWrapper{
        margin-left: 0px;
        text-align: center;
    }
    .wrapperLink{
        flex-direction: column;
    }
}

@media(max-height:700px) {
    
}